



















import { Vue, Component } from 'vue-property-decorator';

@Component
export default class extends Vue {
  get err(): string {
    return this.$route.query.err as string;
  }
}
